import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const fwWingstopLossPreventionConfig: IConfigsType = {
  fw_wingstop: {
    '/:brand/corporate_loss_prevention': {
      variables: ['corporateGroup', 'discountType', 'lastTimeRange'],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      lossPreventionKpis: {
        type: 'list',
        api: 'fwWingstopListLocationGroupLossPreventionKpis',
        title: 'Loss Prevention',
        width: '160px',
        fields: [
          {
            key: 'discountCompsTotal',
            title: 'Total Discounts',
          },
          {
            key: 'lossPreventionCompsCashOverOrShort',
            title: 'Cash +/-',
          },
          {
            key: 'lossPreventionCheckCountVoids',
            title: 'Void Count',
          },
        ],
      },
      discountTypeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Discounts',
          },
          columns: {
            type: 'select',
            value: 'discountType.value',
            onChange: 'discountType.onChange',
            options: 'discountType.options',
            label: 'Type',
          },
        },
      },
      discountBreakdownByStore: {
        type: 'table-v2',
        api: 'fwWingstopLossPrevention',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: '100DiscEmp',
            title: '100% Disc. / Emp',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("100DiscEmp")) %>',
            children: [
              {
                key: 'discountComps100DiscEmp',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercent100DiscEmp',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values.discountCompsPercent100DiscEmpHeatmap %>',
                },
              },
            ],
          },
          {
            key: '100Remake',
            title: '100% Remake',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("100Remake")) %>',
            children: [
              {
                key: 'discountComps100Remake',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercent100Remake',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values.discountCompsPercent100RemakeHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'otherSCombined',
            title: 'Other % Combined',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("otherSCombined")) %>',
            children: [
              {
                key: 'discountCompsOtherSCombined',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentOtherSCombined',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values.discountCompsPercentOtherSCombinedHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'allWithPurch',
            title: 'All With Purch',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("allWithPurch")) %>',
            children: [
              {
                key: 'discountCompsAllWithPurch',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentAllWithPurch',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values.discountCompsPercentAllWithPurchHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'allNonPurchPromos',
            title: 'All Non Purch Promos',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("allNonPurchPromos")) %>',
            children: [
              {
                key: 'discountCompsAllNonPurchPromos',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentAllNonPurchPromos',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values.discountCompsPercentAllNonPurchPromosHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'openDiscounts',
            title: 'Open Discounts',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("openDiscounts")) %>',
            children: [
              {
                key: 'discountCompsOpenDiscounts',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentOpenDiscounts',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values.discountCompsPercentOpenDiscountsHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'total',
            title: 'Total Discounts',
            align: 'center',
            children: [
              {
                key: 'discountCompsTotal',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentTotal',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values.discountCompsPercentTotalHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'grossSales',
            title: 'Net Sales',
            width: '150px',
            align: 'center',
            sortable: true,
          },
        ],
      },
      discountTrendTimeRangeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Discounts Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
            label: ' ',
          },
        },
      },
      discountCompsTrendChart: {
        type: 'line-chart',
        api: 'trendLocationGroupDiscount',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'discountCompsTotal',
            label: 'Total Discount',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: '$',
              },
            },
          },
        },
      },
      cachBreakdownByStore: {
        type: 'table-v2',
        title: 'Cash',
        api: 'fwWingstopLossPrevention',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'cashOverOrShort',
            title: 'Cash +/-',
            align: 'center',
            children: [
              {
                key: 'lossPreventionCompsCashOverOrShort',
                title: '$',
                width: '150px',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'voids',
            title: 'Void',
            align: 'center',
            children: [
              {
                key: 'lossPreventionCheckCountVoids',
                title: '#',
                width: '150px',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      lossPreventionsTrendTimeRangeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Void Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
            label: ' ',
          },
        },
      },
      lossPreventionsTrendChart: {
        type: 'line-chart',
        api: 'trendLocationGroupLossPrevention',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'lossPreventionCheckCountVoids',
            label: 'Void',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
        ],
        scaleTypes: {
          y: {
            type: 'string',
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: 'Count',
              },
            },
          },
        },
      },
    },
    '/:brand/loss_prevention': {
      groupFilter: {
        api: 'locationFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
        ],
      },
      title: {
        type: 'title',
        title: 'Loss Prevention',
      },
      // expoAi: {
      //   type: 'ai-summary-box',
      //   api: 'locationLossPreventionAiSummary',
      // },
      listLocationLossPreventionKpis: {
        type: 'list',
        api: 'fwWingstopListLocationLossPreventionKpis',
        width: '160px',
        fields: [
          {
            key: 'discountCompsTotal',
            title: 'Total Discounts',
          },
          {
            key: 'lossPreventionCompsCashOverOrShort',
            title: 'Cash +/-',
          },
          {
            key: 'lossPreventionCheckCountVoids',
            title: 'Void Count',
          },
        ],
      },
      opsCompsBreakdown: {
        type: 'table-v2',
        title: 'Ops Comps Breakdown',
        api: 'fwWingstopLocationOpsCompsBreakdownData',
        columns: [
          {
            key: 'discountCompsRank',
            title: 'Rank',
            width: '50px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'discountName',
            title: 'Discount',
            width: '175px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'discountComps',
            title: '$ Discount',
            width: '100px',
            align: 'center',
            sortable: true,
          },
        ],
      },
      // discountBreakdownByEmployee: {
      //   type: 'table-v2',
      //   title: 'Discounts by Employee',
      //   api: 'burgerworksListEmployeeDiscount',
      //   sorting: [
      //     {
      //       id: 'discountCompsPercentTotal',
      //       desc: true,
      //     },
      //   ],
      //   columns: [
      //     {
      //       key: 'discountTotalPercentRank',
      //       title: 'Rank',
      //       align: 'center',
      //       width: '100px',
      //       sortable: true,
      //     },
      //     {
      //       key: 'employeeName',
      //       title: 'Name',
      //       align: 'center',
      //       width: '250px',
      //       sortable: true,
      //     },
      //     {
      //       key: 'discountTypes',
      //       title: 'Discount Types',
      //       align: 'center',
      //       width: '200px',
      //       sortable: true,
      //     },
      //     {
      //       key: 'discountCheckCountTotal',
      //       title: 'Discount #',
      //       align: 'center',
      //       width: '125px',
      //       sortable: true,
      //     },
      //     {
      //       key: 'discountCompsTotal',
      //       title: 'Discount $',
      //       align: 'center',
      //       width: '125px',
      //       sortable: true,
      //     },
      //     {
      //       key: 'grossSales',
      //       title: 'Gross Sales',
      //       align: 'center',
      //       width: '125px',
      //       sortable: true,
      //     },
      //     {
      //       key: 'discountCompsPercentTotal',
      //       title: 'Discount %',
      //       align: 'center',
      //       width: '125px',
      //       sortable: true,
      //       background: {
      //         range: [0, 100],
      //         colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
      //         value: '<%- values.discountTotalPercentHeatmap %>',
      //       },
      //     },
      //   ],
      // },
      cashHandlingByEmployee: {
        type: 'table-v2',
        title: 'Cash Handling by Employee',
        api: 'fwWingstopListEmployeeLossPrevention',
        sorting: [
          {
            id: 'lossPreventionVoidCheckCount',
            desc: true,
          },
        ],
        columns: [
          {
            key: 'employeeName',
            title: 'Name',
            align: 'center',
            width: '175px',
            sortable: true,
          },
          // {
          //   key: 'checkCount',
          //   title: 'Checks Total #',
          //   align: 'center',
          //   width: '150px',
          //   sortable: true,
          // },
          {
            key: 'void',
            title: 'Void',
            align: 'center',
            children: [
              {
                key: 'lossPreventionVoidCheckCount',
                title: '#',
                align: 'center',
                width: '150px',
                sortable: true,
              },
              // {
              //   key: 'lossPreventionVoidAmount',
              //   title: '$',
              //   align: 'center',
              //   width: '75px',
              //   sortable: true,
              // },
              // {
              //   key: 'lossPreventionVoidCheckCountPercent',
              //   title: '%',
              //   align: 'center',
              //   width: '75px',
              //   sortable: true,
              //   background: {
              //     range: [0, 100],
              //     colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
              //     value:
              //       '<%- values.lossPreventionVoidCheckCountPercentHeatmap %>',
              //   },
              // },
            ],
          },
        ],
      },
    },
  },
}

export default fwWingstopLossPreventionConfig
